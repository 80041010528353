import styled, { css } from "styled-components"
import { colors } from "./variables"

export const ClientsStyle = styled.div`
  ${props =>
    props.noMarginTop &&
    css`
      padding: 0 0 0;
      background: ${colors.lightestGrey};
    `}

  .limitWidth {
    padding: 140px 100px 100px 6.5vw;
  }

  .bgLighter {
    background-color: ${colors.whiteBg};
  }

  .startArea {
    position: relative;
    height: 80vh;
    min-height: 600px;
    background-size: cover;
    background-repeat: no-repeat;

    & > * {
      position: relative;
      z-index: 2;
    }

    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.5);
      z-index: 1;
    }

    .text {
      padding: 20vh 0px 0px 10vw;
      height: 100%;
      box-sizing: border-box;
    }

    h1 {
      color: #ffffff;
      text-transform: uppercase;
      font-family: "Barlow", sans-serif;
      font-size: 4rem;
      font-weight: 700;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.3;
      letter-spacing: 1.04px;
      margin-bottom: 25px;
    }

    p {
      color: #ffffff;
      max-width: 65%;
      font-family: "Barlow", sans-serif;
      font-size: 1.6rem;
      font-weight: 400;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.42;
      letter-spacing: normal;
      text-align: left;
      color: #ffffff;
    }
  }
  .tooltipArea {
    position: relative;
    z-index: 2;
    height: auto;
    width: ${props => (props.noMarginTop ? "100%" : "90%")};
    box-shadow: ${props =>
      props.noMarginTop ? "inherit" : "0 11.5px 15px 0 rgba(0, 0, 0, 0.14)"};
    background-color: ${props =>
      props.noMarginTop ? "inherit" : colors.lightestGrey};
    margin: ${props => (props.noMarginTop ? "0px" : "-150px")} auto 50px auto;

    .text {
      padding: 27.5px 41.5px 0px 41.5px;
    }
    h1 {
      font-family: "Monument";
      font-size: 3rem;
      font-weight: 300;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.32;
      letter-spacing: 3px;
      text-align: center;
      text-transform: uppercase;
      color: ${colors.titleLightSmall};
    }

    .tooltipAllClients {
      padding: 5% 5% 5%;
      margin: 0;
      list-style: none;

      display: grid;
      grid-template-columns: repeat(4, 1fr);
      align-items: center;
      justify-items: center;
      grid-gap: 5vw;

      .logoClient {
        margin: 2%;
        padding-left: 2%;
        padding-right: 2%;
        width: auto;
        line-height: 0;
        font-size: 0;
        color: transparent;

        svg {
          fill: ${colors.titleDarkBig};
          max-width:200px;
          height:100px;
        }
      }

      .extra {
        padding-left: 0.5%;
        padding-right: 0.5%;
      }
    }

    .tooltipButtonContainer {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 200px;
      height: 4rem;
      right: 2.5vw;
      bottom: -1.5vw;
      background-color: ${colors.orange};
      color: #ffffff;
      border: none;
      box-shadow: -3px 6px 8.5px 0 rgba(0, 0, 0, 0.13);
      border-radius: 0;
      text-align: center;
      transition: background-color 0.5s ease, box-shadow 0.5s ease;

      &:hover {
        background-color: ${colors.orangeDark};
        box-shadow: -3px 6px 8.5px 0 rgba(0, 0, 0, 0.13);
      }
      &:hover > .tooltipButton > .tooltipButtonArrow {
        transform: translate(5px);
      }
    }
    .tooltipButton {
      font-size: 1.6rem;
      @media only screen and (min-width: 2100px){
        font-size: 2.2rem;
      }
      width: auto;
      height: auto;
      font-family: "Barlow", sans-serif;
      font-weight: 600;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.2em;
      letter-spacing: normal;
      text-align: center;

      &:hover > .tooltipButtonArrow {
        transform: translate(5px);
      }

      .tooltipButtonArrow {
        fill: #ffffff;
        stroke: #ffffff;
        height: 0.8em;
        width: 25px;
        margin: 0 auto;
        transform: translate(0px);
        transition: transform 0.5s ease;
      }
    }
  }

  @media screen and (max-width: 1440px) {
    .tooltipArea {
      .tooltipButtonContainer {
        width: 155px;
        height: 40px;
        bottom: -1.5vw;
        right: 3vw;
      }
    }
  }

  @media screen and (max-width: 1024px) {
    .tooltipArea {
      .tooltipButtonContainer {
        width: 150px;
        height: 40px;
        bottom: -2vw;
        right: 5vw;
      }
      .tooltipButton {
        font-size: 1.2rem;
        letter-spacing: 0.5px;
      }
    }
  }

  @media screen and (max-width: 1250px) {

    .tooltipArea .tooltipAllClients {
        grid-template-columns: 1fr 1fr 1fr;
        grid-column-gap: 30px;
        grid-row-gap: 30px;
        align-items: center;
        justify-content: center;
        justify-items: center;
        padding: 10%;
        padding-left: ${props => (props.noMarginTop ? "0%" : "10%")};
        }
    }

  @media screen and (max-width: 900px) {
    .startArea {
      height: 50vh;
      min-height: 400px;
    }
    .startArea .text {
      padding: 20vh 30px 20vh 30px;
    }
    .startArea .text h1 {
      font-size: 2.4rem;
      line-height: 30px;
    }
    .startArea .text p {
      font-size: 1.3rem;
      max-width: 100%;
    }
    .tooltipArea {
      margin-top: ${props => (props.noMarginTop ? "0px" : "-100px")};
    }
    .tooltipArea .text {
      padding: 17px 15px 0px 15px;
    }
    .tooltipArea .text h1 {
      font-size: 1.5rem;
      line-height: 18px;
    }
    .tooltipArea .tooltipAllClients {
      grid-template-columns: 1fr 1fr;
    }
    .tooltipArea .tooltipAllClients .logoClient {
    }
    .tooltipArea .tooltipAllClients .logoClient svg {
      width: 100%;
    }
    .tooltipArea .tooltipButtonContainer {
      width: 145px;
      height: 35px;
      bottom: -17.5px;
      right: 25px;
    }
  }
`
